import React, {
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';

import {
    Drawer,
    Divider,
} from '@mui/material';

import {
    EPOS_FILTERS_TITLES,
    IFilterListItem,
} from 'const';

import {
    IFinancialPerformanceReportBaseFilterList,
    IFinancialPerformanceReportFilterList,
    checkOpenExtension,
    countFilterQty,
    toggleFilters,
    getCashiersFiltersBySelectedBetshops,
    getTerminalsFiltersBySelectedBetshops,
} from 'pages/Reports/business';

import { FilterButton, Header, ControlPanel } from 'components/Filters/Filters';
import FiltersWithSearch from 'components/Filters/FiltersWithSearch/FiltersWithSearch';
import DateTimePicker from 'pages/Users/AuditLogs/components/DateTimePicker/DateTimePicker';

import useStyles from 'pages/Reports/Tickets/TicketsReportFilters/styles';

const initOpenExpansions: Partial<Record<keyof IFinancialPerformanceReportBaseFilterList, boolean>> = {};

const initialValidityState = {
    timeRange: true,
};

const BETSHOPS = 'betShops';
const TERMINALS = 'terminals';
const CASHIERS = 'cashiers';

const searchListInitial = {
    [BETSHOPS]: { text: '', isFieldOpen: false },
    [TERMINALS]: { text: '', isFieldOpen: false },
    [CASHIERS]: { text: '', isFieldOpen: false }
};

const checkValidity = (validityState) => Object.values(validityState).every(isValid => isValid);

const FinancialPerformanceReportFilters = function ({
    filtersLists,
    appliedFilters,
    setAppliedFilters,
    filterListInitialWithDate,
    handleClearFilters,
    handleSaveFilters,
    isSaveFilters,
}: {
    filtersLists: IFinancialPerformanceReportBaseFilterList;
    appliedFilters: IFinancialPerformanceReportFilterList;
    setAppliedFilters: (filters: IFinancialPerformanceReportFilterList) => void;
    filterListInitialWithDate: IFinancialPerformanceReportFilterList;
    handleClearFilters: () => void;
    handleSaveFilters: () => void;
    isSaveFilters: boolean;
}) {
    const classes = useStyles({});

    const [filters, setFilters] = useState(filterListInitialWithDate);
    const [searchInFiltersList, setSearchInFiltersList] = useState(searchListInitial);
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    const [openExpansions, setOpenExpansions] = useState(initOpenExpansions);

    const filtersWrapperRef = useRef<HTMLDivElement>();

    useEffect(() => {
        setFilters({ ...appliedFilters });
        setOpenExpansions({ ...checkOpenExtension(appliedFilters) });
    }, Object.values(appliedFilters));

    const [validityState, setValidityState] = useState({ ...initialValidityState });

    const isApplyDisabled = !checkValidity(validityState);

    const setTimeRangeInvalid = useCallback((isInvalid) => {
        setValidityState(validityState => ({ ...validityState, timeRange: !isInvalid }));
    }, []);

    const handleOpenDrawer = () => {
        setFilters({ ...appliedFilters });
        setIsOpenDrawer(true);
    };

    const handleCloseDrawer = () => {
        setOpenExpansions({ ...checkOpenExtension(appliedFilters) });
        setIsOpenDrawer(false);
    };

    const handleFilterClick = useCallback((key: string, item: IFilterListItem) => {
        setFilters(filters => {
            const filterArr = toggleFilters(item, filters[key]);

            return {
                ...filters,
                [key]: filterArr,
            };
        });
    }, []);

    const handleBetshopFilterClick = useCallback((key: string, item: IFilterListItem) => {
        // here
        setFilters(filters => {
            const betShopsFilterArr = toggleFilters(item, filters.betShops);

            return {
                ...filters,
                betShops: betShopsFilterArr,
                cashiers: betShopsFilterArr.length
                    ? getCashiersFiltersBySelectedBetshops(
                        filters.cashiers,
                        betShopsFilterArr
                    )
                    : [],
                terminals: betShopsFilterArr.length
                    ? getTerminalsFiltersBySelectedBetshops(
                        filters.terminals,
                        betShopsFilterArr
                    )
                    : [],
            };
        });
    }, []);

    const handleApplyFilters = () => {
        setOpenExpansions({ ...checkOpenExtension(appliedFilters) });
        setIsOpenDrawer(false);
        setAppliedFilters({ ...filters });
        setSearchInFiltersList({ ...searchListInitial });
    };

    const handleSearchInFilters = (key: string, text: string, isFieldOpen: boolean) => {
        const newValue = { text, isFieldOpen };

        setSearchInFiltersList({ ...searchInFiltersList, [key]: newValue });
    };

    const onClearFilters = () => {
        setOpenExpansions({ ...initOpenExpansions });
        setFilters({ ...filterListInitialWithDate });
        setSearchInFiltersList({ ...searchListInitial });
        handleClearFilters();
    };

    const handleExpanded = useCallback((key: string) => {
        setOpenExpansions(openExpansions => (
            {
                ...openExpansions,
                [key]: !openExpansions[key],
            }
        ));
    }, []);

    const changeDates = useCallback((dates) => {
        setFilters(filters => ({ ...filters, ...dates }));
    }, []);

    return (
        <>
            <FilterButton
                filterQty={countFilterQty(appliedFilters)}
                handleOpenDrawer={handleOpenDrawer}
            />
            <Drawer
                className={classes.drawerWrap}
                classes={{
                    root: classes.drawerRoot,
                    paper: classes.drawerWrap
                }}
                anchor="right"
                open={isOpenDrawer}
                onClose={handleCloseDrawer}
            >
                <div className={classes.drawer}>
                    <Header onCloseClick={handleCloseDrawer} />
                    <div className={classes.filtersWrap}
                        ref={filtersWrapperRef}
                    >
                        <DateTimePicker
                            setApplyDisabled={setTimeRangeInvalid}
                            fromDate={filters.fromDate}
                            toDate={filters.toDate}
                            changeDates={changeDates}
                        />
                        <Divider />
                        <FiltersWithSearch
                            label={BETSHOPS}
                            data-a="filter-with-search-betshops"
                            title={EPOS_FILTERS_TITLES.location}
                            data={filtersLists.betShops}
                            activeFilterIds={filters.betShops}
                            appliedActiveFilters={appliedFilters.betShops}
                            handleFilterClick={handleBetshopFilterClick}
                            expanded={openExpansions.betShops}
                            handleExpanded={handleExpanded}
                            handleSearchInFilters={handleSearchInFilters}
                            searchText={searchInFiltersList[BETSHOPS].text}
                            isSearchFieldShown={searchInFiltersList[BETSHOPS].isFieldOpen}
                            scrollElement={filtersWrapperRef.current}
                        />
                        <FiltersWithSearch
                            label={TERMINALS}
                            data-a="filter-with-search-terminals"
                            title="emp-reports-vouchers-entity-type-kiosk"
                            data={getTerminalsFiltersBySelectedBetshops(filtersLists.terminals, filters.betShops)}
                            activeFilterIds={filters.terminals}
                            appliedActiveFilters={appliedFilters.terminals}
                            handleFilterClick={handleFilterClick}
                            expanded={openExpansions.terminals}
                            handleExpanded={handleExpanded}
                            handleSearchInFilters={handleSearchInFilters}
                            searchText={searchInFiltersList[TERMINALS].text}
                            isSearchFieldShown={searchInFiltersList[TERMINALS].isFieldOpen}
                            scrollElement={filtersWrapperRef.current}
                        />
                        <FiltersWithSearch
                            label={CASHIERS}
                            data-a="filter-with-search-cashiers"
                            title="users-cashier"
                            data={getCashiersFiltersBySelectedBetshops(filtersLists.cashiers, filters.betShops)}
                            activeFilterIds={filters.cashiers}
                            appliedActiveFilters={appliedFilters.cashiers}
                            handleFilterClick={handleFilterClick}
                            expanded={openExpansions.cashiers}
                            handleExpanded={handleExpanded}
                            handleSearchInFilters={handleSearchInFilters}
                            searchText={searchInFiltersList[CASHIERS].text}
                            isSearchFieldShown={searchInFiltersList[CASHIERS].isFieldOpen}
                            scrollElement={filtersWrapperRef.current}
                        />
                    </div>
                    <ControlPanel
                        disabled={isApplyDisabled}
                        onApplyFilters={handleApplyFilters}
                        onClearFilters={onClearFilters}
                        onSaveFilters={handleSaveFilters}
                        isSaveFilters={isSaveFilters}
                    />
                </div>
            </Drawer>
        </>
    );
};

export default FinancialPerformanceReportFilters;
